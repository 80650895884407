<template>
  <div>
    <NavigationBar titel="Abrechnung" :actions="actions"></NavigationBar>

    <section v-if="!loading && noBillingAccountConnected">
      <v-container class="fill-height mt-16">
        <v-row align="top" justify="center">
          <v-col cols="12" sm="12" md="8">
            <v-card>
              <v-card-title class="d-flex justify-center"
                ><div class="text-center">
                  <v-avatar color="primary" rounded size="70" class="mb-4">
                    <v-icon x-large color="white">
                      mdi-credit-card-off-outline</v-icon
                    >
                  </v-avatar>
                  <div>Kein Abrechnungskonto verknüpft</div>
                </div>
              </v-card-title>
              <v-card-actions
                ><v-btn
                  @click="useExistingBillingAccount()"
                  depressed
                  class="mb-2 ml-2"
                  ><v-icon left>mdi-link-variant</v-icon>Bestehendes
                  Abrechnungskonto verwenden</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                  @click="createNewBillingAccount()"
                  depressed
                  color="primary"
                  class="mb-2 mr-2"
                  ><v-icon left>mdi-credit-card-plus-outline</v-icon>Neues
                  Abrechnungskonto erstellen</v-btn
                ></v-card-actions
              >
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section v-else>
      <v-container fluid>
        <v-row class="match-height">
          <v-col cols="12" md="6"
            ><v-card>
              <v-card-title class="body-2 v-card__subtitle">
                <v-icon left small class="mr-2">mdi-account-box</v-icon
                >Kundendaten
              </v-card-title>
              <v-card-text class="">
                <v-row dense class="match-height">
                  <v-col cols="12">
                    <v-card outlined>
                      <v-list-item @click="copyToClipboard(customerData.name)">
                        <v-list-item-content>
                          <v-list-item-title class="font-weight-medium">{{
                            customerData.name ? customerData.name : "N/A"
                          }}</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-btn
                            depressed
                            icon
                            text
                            small
                            color="primary"
                            @click="copyToClipboard(customerData.name)"
                          >
                            <v-icon small>mdi-content-copy</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item
                        dense
                        @click="copyToClipboard(customerData.id)"
                      >
                        <v-list-item-content>
                          <v-list-item-title>{{
                            customerData.id ? customerData.id : "N/A"
                          }}</v-list-item-title>

                          <v-list-item-subtitle
                            >Kundenummer</v-list-item-subtitle
                          >
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-btn
                            depressed
                            icon
                            text
                            small
                            color="primary"
                            @click="copyToClipboard(customerData.id)"
                          >
                            <v-icon small>mdi-content-copy</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card>
              <v-card-title class="body-2 v-card__subtitle">
                <v-icon left small class="mr-2">mdi-credit-card-outline</v-icon
                >Kostenvorschau
              </v-card-title>
              <v-card-text>
                <v-row dense class="match-height">
                  <v-col cols="12">
                    <v-card outlined>
                      <v-list-item disabled>
                        <v-list-item-icon disabled>
                          <v-avatar
                            size="40"
                            rounded
                            color="grey lighten-4"
                            disabled
                          >
                            <v-icon color="primary" disabled>
                              mdi-invoice-text-clock
                            </v-icon>
                          </v-avatar>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title
                            >Keine Rechnungsvorschau
                            verfügbar</v-list-item-title
                          >

                          <v-list-item-subtitle
                            >Erst nach Rechnungsfinalisierung
                            möglich</v-list-item-subtitle
                          >
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-btn depressed icon small color="primary" disabled>
                            <v-icon>mdi-open-in-app</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-card>
              <v-data-table
                :items="subscriptions"
                :headers="subscriptionsHeaders"
                sort-by="startDate"
                item-key="id"
                :mobile-breakpoint="0"
                :items-per-page="5"
                disable-sort
              >
                <template v-slot:top>
                  <slot name="top">
                    <v-card flat>
                      <v-card-title class="body-2 v-card__subtitle">
                        <v-icon left small class="mr-2"
                          >mdi-playlist-check</v-icon
                        >Abonnements
                      </v-card-title>
                    </v-card>
                  </slot>
                </template>
                <template v-slot:[`item.startDate`]="{ item }">
                  <div class="font-weight-medium mt-2">
                    <span v-if="item.nickname || item.plan.nickname">{{
                      item.nickname ? item.nickname : item.plan.nickname
                    }}</span>
                  </div>
                  <div style="line-height: 1.2" class="mb-2">
                    <small
                      v-if="item.currentPeriodStart && item.currentPeriodEnd"
                      ><strong>Aktueller Abrechnungszeitraum: </strong>
                      {{ timestampConverter(item.currentPeriodStart) }} bis
                      {{ timestampConverter(item.currentPeriodEnd) }}</small
                    >
                    <br />
                    <small
                      ><strong>Startzeitpunkt: </strong>
                      {{ timestampConverter(item.startDate) }}</small
                    >
                    <small v-if="item.endedAt"
                      >, <strong>Endzeitpunkt: </strong>
                      {{ timestampConverter(item.endedAt) }}</small
                    >
                  </div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <subscription-status-chip :status="item.status" small>
                  </subscription-status-chip>
                </template>
                <template v-slot:[`item.actions`]="{}">
                  <v-btn color="error" depressed outlined text small disabled
                    ><v-icon left small>mdi-cancel</v-icon>Kündigen</v-btn
                  >
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card>
              <v-data-table
                :items="invoices"
                :headers="invoicesHeaders"
                item-key="id"
                :mobile-breakpoint="0"
                :items-per-page="5"
                disable-sort
              >
                <template v-slot:top>
                  <slot name="top">
                    <v-card flat>
                      <v-card-title class="body-2 v-card__subtitle">
                        <v-icon left small class="mr-2"
                          >mdi-invoice-text-multiple</v-icon
                        >Rechnungen
                      </v-card-title>
                    </v-card>
                  </slot>
                </template>
                <template v-slot:[`item.number`]="{ item }">
                  <div class="font-weight-medium mt-2">
                    {{
                      item.number
                        ? "Rechnung " + item.number
                        : "Noch keine Rechnungsnummer vergeben"
                    }}
                  </div>
                  <div
                    style="line-height: 1.2"
                    class="mb-2"
                    v-if="item.periodStart && item.periodEnd"
                  >
                    <small
                      ><strong>Leistungszeitraum: </strong>
                      {{ timestampConverter(item.periodStart) }} bis
                      {{ timestampConverter(item.periodEnd) }}</small
                    >
                    <br />
                    <small
                      ><strong>Gesamtpreis: </strong>
                      <code class="ml-1">{{
                        getAmountFromCents(item.total, item.currency)
                      }}</code>
                      <span v-if="item.amountRemaining !== item.total">
                        davon noch fällig:
                        <code class="ml-1">{{
                          getAmountFromCents(
                            item.amountRemaining,
                            item.currency
                          )
                        }}</code>
                      </span>
                    </small>
                    <br />
                    <small v-if="item.status === 'open' && item.dueDate"
                      ><strong>Fällig bis: </strong>
                      {{ timestampConverter(item.dueDate) }}
                    </small>
                  </div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <invoice-status-chip :status="item.status" small>
                  </invoice-status-chip>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom v-if="item.invoicePdf">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="openInvoiceInBillingPortal(item.invoicePdf)"
                        ><v-icon small>mdi-file-download-outline</v-icon></v-btn
                      >
                    </template>
                    <span>Rechnung herunterladen</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-data-table
                v-model="selectedLicenses"
                :items="licenses"
                :headers="licensesHeaders"
                :search="licensesSearch"
                sort-by="name"
                :group-by="licensesGroupBy"
                item-key="licenseId"
                :mobile-breakpoint="0"
                selectable-key="isSelectable"
                show-select
                locale="de-DE"
              >
                <template v-slot:top>
                  <slot name="top">
                    <v-card flat>
                      <v-card-subtitle>
                        <v-row class="justify-end" dense>
                          <v-col>
                            <v-icon left small class="mr-2"
                              >mdi-account-credit-card</v-icon
                            >Lizenzverwendung
                          </v-col>
                          <v-col cols="12" sm="3" md="3">
                            <v-text-field
                              v-model="licensesSearch"
                              outlined
                              append-icon="mdi-magnify"
                              label="Lizenzen durchsuchen"
                              :disabled="!licenses.length"
                              hide-details="auto"
                              clearable
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="auto">
                            <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  depressed
                                  v-bind="attrs"
                                  v-on="on"
                                  :disabled="!licenses.length"
                                >
                                  <v-icon left>mdi-dots-vertical</v-icon>
                                  Aktionen
                                </v-btn>
                              </template>
                              <v-list nav>
                                <v-list-item
                                  link
                                  dense
                                  @click="toggleCancelSelectedLicensesDialog()"
                                >
                                  <v-list-item-icon>
                                    <v-icon dense>mdi-cancel</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title
                                    >Ausgewählte Lizenzen
                                    kündigen</v-list-item-title
                                  >
                                </v-list-item>
                                <v-list-item
                                  link
                                  dense
                                  @click="toggleChangeSelectedLicensesDialog()"
                                >
                                  <v-list-item-icon>
                                    <v-icon dense>mdi-swap-horizontal</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title
                                    >Ausgewählte Lizenzen
                                    wechseln</v-list-item-title
                                  >
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-col>
                          <v-col cols="auto">
                            <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  depressed
                                  v-bind="attrs"
                                  v-on="on"
                                  class="mr-2"
                                  :disabled="!licenses.length"
                                >
                                  <span>
                                    Gruppieren nach
                                    <strong>{{ licenseFilterText }}</strong>
                                  </span>
                                  <v-icon right>mdi-menu-down</v-icon>
                                </v-btn>
                              </template>
                              <v-list nav>
                                <v-list-item-group
                                  v-model="licensesGroupBy"
                                  mandatory
                                >
                                  <v-list-item
                                    link
                                    dense
                                    value="user.displayName"
                                  >
                                    <v-list-item-icon>
                                      <v-icon dense>mdi-account</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title
                                      >Benutzerkonto</v-list-item-title
                                    >
                                  </v-list-item>
                                  <v-list-item link dense value="status">
                                    <v-list-item-icon>
                                      <v-icon dense>mdi-list-status</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title
                                      >Status</v-list-item-title
                                    >
                                  </v-list-item>
                                  <v-list-item link dense value="license.title">
                                    <v-list-item-icon>
                                      <v-icon dense>mdi-license</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title
                                      >Lizenz</v-list-item-title
                                    >
                                  </v-list-item>
                                  <v-list-item
                                    link
                                    dense
                                    value="invoiceId"
                                    disabled
                                  >
                                    <v-list-item-icon>
                                      <v-icon dense disabled
                                        >mdi-invoice-list</v-icon
                                      >
                                    </v-list-item-icon>
                                    <v-list-item-title
                                      >Rechnung</v-list-item-title
                                    >
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-card-subtitle>
                    </v-card>
                  </slot>
                </template>
                <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
                  <th :colspan="licensesHeaders.length">
                    <v-icon @click="toggle" class="mr-2"
                      >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                    </v-icon>
                    <!-- {{ items[0][licensesGroupBy] }} -->
                    <span class="ml-1 grey--text font-weight-regular"
                      >({{ items.length }})</span
                    >
                  </th>
                </template>
                <template v-slot:[`item.user.displayName`]="{ item }">
                  <div class="font-weight-medium mt-2">
                    {{ item.user.firstName }} {{ item.user.lastName }}
                  </div>
                  <div style="line-height: 1.2" class="mb-2">
                    <small
                      ><strong>UID: </strong
                      ><code>{{ item.user.uid }}</code></small
                    >
                    <br />
                    <small
                      ><strong>Leistungszeitraum: </strong>Ab
                      {{ timestampConverter(item.periodStart) }}
                      <span v-if="item.periodEnd">
                        bis {{ timestampConverter(item.periodEnd) }}</span
                      ></small
                    >
                    <br />
                    <small
                      ><strong>Geschätzte Kosten:</strong>
                      <code class="ml-1">{{
                        getAmountFromCents(item.priceUnitAmount, item.currency)
                      }}</code>
                    </small>
                    <br />
                    <small><strong>Lizenz: </strong>{{ item.name }}</small>
                  </div>
                </template>
                <template v-slot:[`item.organizationId`]="{ item }">
                  <v-chip outlined small>
                    <pre>{{ item.organizationId }}</pre>
                  </v-chip>
                </template>
                <template v-slot:[`item.license.title`]="{ item }">
                  <v-chip outlined small>{{ item.license.title }}</v-chip>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <license-status-chip :status="item.status" small>
                  </license-status-chip>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn
                    small
                    color="primary"
                    text
                    outlined
                    @click="toggleChangeSelectedLicensesDialog(item)"
                    class="mr-1 my-1"
                  >
                    <v-icon small left>mdi-swap-horizontal</v-icon>Wechseln
                  </v-btn>

                  <v-btn
                    small
                    depressed
                    outlined
                    text
                    :disabled="
                      item.status === 'canceled' ||
                      item.status === 'pendingCancellation'
                    "
                    color="error"
                    @click="toggleCancelSelectedLicensesDialog(item)"
                    class="mr-1 my-1"
                  >
                    <v-icon small left>mdi-cancel</v-icon>Kündigen
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section>
      <CustomDialog
        v-if="createStripePortalSessionDialog.model"
        @close="toggleCreateStripePortalSessionDialog()"
        title="Abrechnungsportal öffnen"
        text="Im Abrechnungsportal können Kundendaten bearbeitet werden sowie ebenfalls Rechnungen und Zahlungsbelege heruntergeladen werden. Dazu verlässt Du diese Seite und wirst zu unserem Abrechnungsdienstleister Stripe weitergeleitet."
        :loading="createStripePortalSessionDialog.loading"
        :btnAction="createStripePortalSession"
        :errorMessage="createStripePortalSessionDialog.errorMessage"
        width="s"
      ></CustomDialog>
      <CustomDialog
        v-if="cancelSelectedLicensesDialog.model"
        @close="toggleCancelSelectedLicensesDialog()"
        title="Ausgewählte Lizenzen kündigen"
        text="Für ausgewählte Lizenzen wird eine Kündigung zum nächstmöglichen Zeitpunkt im Abrechnungszyklus eingeleitet. Die Lizenzen bleiben bis zum Ende des aktuellen Abrechnungszeitraums aktiv."
        :loading="cancelSelectedLicensesDialog.loading"
        :btnAction="cancelSelectedLicenses"
        :errorMessage="cancelSelectedLicensesDialog.errorMessage"
        width="s"
      ></CustomDialog>
      <CustomDialog
        v-if="changeSelectedLicensesDialog.model"
        @close="toggleChangeSelectedLicensesDialog()"
        title="Ausgewählte Lizenzen wechseln"
        text="Für ausgewählte Lizenzen wird eine Lizenzänderung zum nächstmöglichen Zeitpunkt im Abrechnungszyklus eingeleitet."
        :loading="changeSelectedLicensesDialog.loading"
        :btnAction="changeSelectedLicenses"
        :btnDisabled="!changeSelectedLicensesDialog.productId"
        :errorMessage="changeSelectedLicensesDialog.errorMessage"
        width="s"
      >
        <template v-slot:content>
          <v-form @submit.prevent="changeSelectedLicenses()">
            <v-select
              v-model="changeSelectedLicensesDialog.productId"
              :items="products"
              item-text="name"
              item-value="id"
              item-key="id"
              persistent-hint
              hint="_"
              small-chips
              chips
              label="Neue Lizenz auswählen"
              outlined
              class="mt-2"
            >
              <template v-slot:message>
                Lizenzen bestimmen die Funktionalität, die ein Benutzer
                innerhalb der Organisation nutzen kann. Die Auswahl der Lizenz
                kann Kosten verursachen. Eine detaillierte Auflistung über
                anfallende Kosten ist in unserem
                <a href="#" class="text-decoration-none"
                  >Preis- und Leistungsverzeichnis<v-icon
                    x-small
                    class="ml-1"
                    color="primary"
                    >mdi-open-in-new</v-icon
                  ></a
                >
                definiert.
              </template>
            </v-select>
          </v-form>
        </template>
      </CustomDialog>
    </section>
    <v-btn @click="addLicenses()">Add Licenses</v-btn>
  </div>
</template>

<script>
import { db, functions } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import SubscriptionStatusChip from "@/components/admin/billing/SubscriptionStatusChip.vue";
import InvoiceStatusChip from "@/components/admin/billing/InvoiceStatusChip.vue";
import LicenseStatusChip from "@/components/admin/billing/LicenseStatusChip.vue";
export default {
  name: "admin-billing",
  components: {
    NavigationBar,
    SubscriptionStatusChip,
    InvoiceStatusChip,
    LicenseStatusChip,
    CustomDialog,
  },
  data() {
    return {
      actions: [
        {
          title: "Abrechnungsportal öffnen",
          icon: "mdi-open-in-new",
          actionStyle: "textBtnSubmit",
          color: "primary",
          function: this.toggleCreateStripePortalSessionDialog,
        },
      ],
      createStripePortalSessionDialog: {
        model: false,
        loading: false,
        errorMessage: "",
      },
      cancelSelectedLicensesDialog: {
        model: false,
        loading: false,
        errorMessage: "",
      },
      changeSelectedLicensesDialog: {
        model: false,
        loading: false,
        errorMessage: "",
        productId: "",
      },
      selectedLicenses: [],
      licensesGroupBy: "license.title",
      licensesSearch: "",
      subscriptionsHeaders: [
        { text: "Abonnehmentdetails", value: "startDate" },
        { text: "Status", value: "status" },
        { text: "", value: "actions", sortable: false, align: "right" },
      ],
      invoicesHeaders: [
        { text: "Rechnungssdetails", value: "number" },
        { text: "Status", value: "status" },
        { text: "", value: "actions", sortable: false, align: "right" },
      ],
      licensesHeaders: [
        { text: "Benutzerkonto", value: "user.displayName" },
        { text: "Lizenz", value: "license.title" },
        { text: "Organisation", value: "organizationId" },
        { text: "Status", value: "status" },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      invoiceIdFilter: "",
      loading: true,
      customerData: null,
      invoices: [],
      licenses: [],
      products: [],
      subscriptions: [],
      upcomingInvoice: null,
      error: null,
    };
  },
  computed: {
    organizationBillingConfig() {
      return this.$store.state.organization.activeOrganization.billing;
    },
    totalActiveLicenses() {
      return this.licenses.filter((license) => license.status === "active")
        .length;
    },
    licenseFilterText() {
      if (this.licensesGroupBy === "user.displayName") {
        return "Benutzerkonto";
      } else if (this.licensesGroupBy === "status") {
        return "Status";
      } else if (this.licensesGroupBy === "license.title") {
        return "Lizenz";
      } else if (this.licensesGroupBy === "invoiceId") {
        return "Rechnung";
      } else {
        return "Fehler";
      }
    },
    // loading() {
    //   return false;
    // },
    noBillingAccountConnected() {
      return false;
      // return this.$store.state.organization.activeOrganization.config
      //   .inMaintenance;
    },
    // invoices() {
    //   return [
    //     // {
    //     //   id: "1",
    //     //   number: "RE-12001-2024-001",
    //     //   date: "DD.MM.YYYY",
    //     //   amount: 800,
    //     //   currency: "EUR",
    //     //   status: "active",
    //     // },
    //   ];
    // },
    items() {
      // filter this.items based on invoice.id and invoiceIdFilter
      const items = [
        {
          firstName: "John",
          lastName: "Doe",
          invoice: {
            id: "1",
          },
          period: {
            start: "DD.MM.YYYY",
            end: "DD.MM.YYYY",
          },
          plan: {
            title: "Standard (Kostenpflichtig)",
            id: "",
          },
          estimatedCost: {
            amount: 800,
            currency: "EUR",
          },
          status: "active",
        },
      ];
      return items.filter((item) => {
        return item.invoice.id === this.invoiceIdFilter;
      });
    },
  },
  created() {
    if (this.organizationBillingConfig.customerId) {
      this.getCustomerData()
        .then(() => {
          return Promise.all([
            this.getInvoices(),
            this.getLicenses(),
            this.getSubscriptions(),
            this.getProducts(),
          ]);
        })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.error = error;
          console.error(error);
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  },
  methods: {
    async getCustomerData() {
      try {
        const customerId = this.organizationBillingConfig.customerId;
        const customerDoc = await db
          .collection("customers")
          .doc(customerId)
          .get();
        this.customerData = customerDoc.data();
      } catch (error) {
        throw new Error("Failed to get customer data: " + error);
      }
    },
    async getInvoices() {
      try {
        const customerId = this.organizationBillingConfig.customerId;
        const invoicesSnapshot = await db
          .collection("customers")
          .doc(customerId)
          .collection("invoices")
          .orderBy("created", "desc")
          .get();
        this.invoices = invoicesSnapshot.docs.map((doc) => {
          return doc.data();
        });
      } catch (error) {
        throw new Error("Failed to get invoices: " + error);
      }
    },
    async getSubscriptions() {
      try {
        const customerId = this.organizationBillingConfig.customerId;
        const subscriptionsSnapshot = await db
          .collection("customers")
          .doc(customerId)
          .collection("subscriptions")
          .get();

        const subscriptions = await Promise.all(
          subscriptionsSnapshot.docs.map(async (doc) => {
            const subscription = doc.data();
            let schedule = null;

            if (subscription.schedule) {
              const scheduleDoc = await db
                .collection("customers")
                .doc(customerId)
                .collection("subscription_schedules")
                .doc(subscription.schedule)
                .get();

              if (scheduleDoc.exists) {
                schedule = scheduleDoc.data();
              }
            }

            return {
              ...subscription,
              schedule,
            };
          })
        );

        this.subscriptions = subscriptions;
      } catch (error) {
        throw new Error("Failed to get subscriptions: " + error);
      }
    },
    async getLicenses() {
      try {
        const customerId = this.organizationBillingConfig.customerId;
        const licensesSnapshot = await db
          .collection(`customers/${customerId}/licenses`)
          .get();
        this.licenses = licensesSnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            isSelectable:
              data.status === "active" || data.status === "pendingCancellation",
          };
        });
      } catch (error) {
        throw new Error("Failed to get licenses: " + error);
      }
    },
    getProducts() {
      // get products from firestore "products" collection}
      db.collection("products")
        .where("active", "==", true)
        .where("userVisible", "==", true)
        .where("userSelectable", "==", true)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            this.products.push({ name: data.name, id: doc.id });
          });
        });
    },
    toggleCreateStripePortalSessionDialog() {
      this.createStripePortalSessionDialog.model =
        !this.createStripePortalSessionDialog.model;
    },
    toggleCancelSelectedLicensesDialog(item) {
      if (item) {
        this.selectedLicenses = [item];
      }
      this.cancelSelectedLicensesDialog.model =
        !this.cancelSelectedLicensesDialog.model;
    },
    toggleChangeSelectedLicensesDialog(item) {
      if (item) {
        this.selectedLicenses = [item];
      }
      this.changeSelectedLicensesDialog.model =
        !this.changeSelectedLicensesDialog.model;
    },
    cancelSelectedLicenses() {
      this.cancelSelectedLicensesDialog.loading = true;

      const callFunction = functions.httpsCallable(
        "stripeBilling-cancelLicenses"
      );
      return callFunction({
        customerId: this.customerData.id,
        licenseIds: this.selectedLicenses.map((license) => license.licenseId),
      })
        .then((result) => {
          if (result.data.success) {
            // this.showSuccess = true;
            this.cancelSelectedLicensesDialog.loading = false;
            this.toggleCancelSelectedLicensesDialog();
            this.getLicenses();
          } else {
            console.log(result.data);
            this.cancelSelectedLicensesDialog.errorMessage =
              result.data.error.message;
            this.cancelSelectedLicensesDialog.loading = false;
          }
        })
        .catch((error) => {
          // handle error
          // console.error(err.errorMsg);
          console.error(error);
          // this.errorMsg = err.message;
          this.cancelSelectedLicensesDialog.errorMessage = error.message;
          this.cancelSelectedLicensesDialog.loading = false;
        });
    },
    addLicenses() {
      const callFunction = functions.httpsCallable(
        "stripeBilling-createSubscription"
      );
      return callFunction({
        organizationId: this.$route.params.organizationId,
      })
        .then((result) => {
          if (result.data.success) {
            alert("success");
          }
        })
        .catch((error) => {
          // handle error
          console.log(error.data);
          console.error(error);
        });
    },
    changeSelectedLicenses() {
      this.changeSelectedLicensesDialog.loading = true;

      const callFunction = functions.httpsCallable(
        "stripeBilling-changeLicenses"
      );
      return callFunction({
        organizationId: this.$route.params.organizationId,
        productId: this.changeSelectedLicensesDialog.productId,
        licenseIds: this.selectedLicenses.map((license) => license.licenseId),
      })
        .then((result) => {
          if (result.data.success) {
            // this.showSuccess = true;
            this.changeSelectedLicensesDialog.loading = false;
            this.toggleChangeSelectedLicensesDialog();
            this.getLicenses();
          } else {
            this.changeSelectedLicensesDialog.errorMessage =
              "Fehler beim Wechseln der Lizenzen, kontaktieren Sie den Support";
            this.changeSelectedLicensesDialog.loading = false;
          }
        })
        .catch((error) => {
          // handle error
          // console.error(err.errorMsg);
          console.error(error.data);
          console.error(error);
          // this.errorMsg = err.message;
          this.changeSelectedLicensesDialog.errorMessage = error.message;
          this.changeSelectedLicensesDialog.loading = false;
        });
    },
    createStripePortalSession() {
      this.createStripePortalSessionDialog.loading = true;

      const callFunction = functions.httpsCallable(
        "stripeBilling-createStripePortalSession"
      );
      return callFunction({
        customerId: this.customerData.id,
        returnUrl: window.location.href,
      })
        .then((result) => {
          if (result.data.success) {
            // this.showSuccess = true;
            this.createStripePortalSessionDialog.model = false;
            this.createStripePortalSessionDialog.loading = false;
            this.toggleCreateStripePortalSessionDialog();
            window.location.href = result.data.url;
          } else {
            this.createStripePortalSessionDialog.errorMessage =
              result.data.error.message;
            this.createStripePortalSessionDialog.loading = false;
          }
        })
        .catch((error) => {
          // handle error
          // console.error(err.errorMsg);
          console.error(error);
          // this.errorMsg = err.message;
          this.createStripePortalSessionDialog.errorMessage = error.message;
          this.createStripePortalSessionDialog.loading = false;
        });
    },
    getAmountFromCents(amount, currency) {
      return (
        (amount / 100).toFixed(2).replace(".", ",") +
        " " +
        currency.toUpperCase()
      );
    },
    useExistingBillingAccount() {
      alert(
        "Um ein bestehendes Abrechnungskonto zu verwenden, müssen Sie sich an den Support wenden.\n\nGeben Sie dabei folgende Daten an:\n\nIhre existierende Kundenummer\nZu verknüpfende Organisations-ID: " +
          this.$route.params.organizationId
      );
    },
    createNewBillingAccount() {
      alert(
        "Um ein neues Abrechnungskonto zu erstellen, müssen Sie sich an den Support wenden.\n\nGeben Sie dabei folgende Daten an:\n\nZu verknüpfende Organisations-ID: " +
          this.$route.params.organizationId
      );
    },
    openInvoiceInBillingPortal(invoicePdfUrl) {
      window.open(invoicePdfUrl, "_blank");
    },
    timestampConverter(timestamp) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>
