<template>
  <data-chip
    :text="statusText(status)"
    :color="statusColor(status)"
    :small="small"
  >
  </data-chip>
</template>

<script>
import DataChip from "@/components/_systemwide/DataChip.vue";
export default {
  name: "invoice-status-chip",
  components: {
    DataChip,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    statusText(statusId) {
      switch (statusId) {
        case "draft":
          return "Entwurf";
        case "open":
          return "Offen";
        case "paid":
          return "Bezahlt";
        case "uncollectible":
          return "Uneinbringlich";
        case "void":
          return "Ungültig";
        default:
          return "Unbekannt";
      }
    },
    statusColor(statusId) {
      switch (statusId) {
        case "draft":
          return "grey";
        case "open":
          return "blue";
        case "paid":
          return "green";
        case "uncollectible":
          return "grey";
        case "void":
          return "orange";
        default:
          return "grey";
      }
    },
  },
};
</script>
