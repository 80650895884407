<template>
  <data-chip
    :text="statusText(status)"
    :color="statusColor(status)"
    :small="small"
  >
  </data-chip>
</template>

<script>
import DataChip from "@/components/_systemwide/DataChip.vue";
export default {
  name: "subscription-status-chip",
  components: {
    DataChip,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // Possible values are incomplete, incomplete_expired, trialing, active, past_due, canceled, unpaid, or paused.
    statusText(statusId) {
      switch (statusId) {
        case "incomplete":
          return "Unvollständig";
        case "incomplete_expired":
          return "Unvollständig Abgelaufen";
        case "trialing":
          return "Testphase";
        case "active":
          return "Aktiv";
        case "past_due":
          return "Überfällig";
        case "canceled":
          return "Storniert";
        case "unpaid":
          return "Unbezahlt";
        case "paused":
          return "Pausiert";
        default:
          return "Unbekannt";
      }
    },
    statusColor(statusId) {
      switch (statusId) {
        case "incomplete":
          return "orange";
        case "trialing":
          return "blue";
        case "active":
          return "green";
        case "past_due":
        case "unpaid":
          return "red";
        case "paused":
          return "yellow";
        case "incomplete_expired":
        case "canceled":
        default:
          return "grey";
      }
    },
  },
};
</script>
