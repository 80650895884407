<template>
  <data-chip
    :text="statusText(status)"
    :color="statusColor(status)"
    :small="small"
  >
  </data-chip>
</template>

<script>
import DataChip from "@/components/_systemwide/DataChip.vue";
export default {
  name: "license-status-chip",
  components: {
    DataChip,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    statusText(statusId) {
      switch (statusId) {
        case "active":
          return "Aktiv";
        case "pending":
        case "pendingActivation":
          return "Aktivierung ausstehend";
        case "pendingCancellation":
          return "Kündigung ausstehend";
        case "pendingChange":
          return "Änderung ausstehend";
        case "canceled":
          return "Gekündigt";
        case "expired":
          return "Abgelaufen";
        case "suspended":
          return "Ausgesetzt";
        default:
          return "Unbekannt";
      }
    },
    statusColor(statusId) {
      switch (statusId) {
        case "active":
          return "green";
        case "pending":
        case "pendingActivation":
        case "pendingCancellation":
        case "pendingChange":
          return "purple";
        case "canceled":
          return "red";
        case "expired":
          return "grey";
        case "suspended":
          return "orange";
        default:
          return "grey";
      }
    },
  },
};
</script>
